<template>
    <div>
        <div>
            <div class="col-md-12">
                <div class="row" style="margin-top:20px">
                    <div class="col-md-6">
                        <h3 class="page-header">
                            <i class="fa fa fa-users animated bounceInDown show-info"></i>
                            Party Report
                        </h3>
                    </div>
                    <div class="col-md-6 ">
                        <div class="row">
                            <div class="col-md-10">
                                <div class="row" style="padding-top:18px;justify-content:flex-end;">
                                    <div class="col-md-2" style="margin-right:25px;">
                                        <button @click="refreshPage()" class="btn btn-primary"><i
                                                class="fa fa-refresh"></i>
                                            Refresh</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <div class="info-box bg-success-dark text-white flex-center-row -">
                            <div class="info-icon bg-purple">
                                <i class="fa fa fa-user fa-2x"></i>
                            </div>
                            <div class="info-details">
                                <h4>Total Party:
                                    <span class="badge bg-danger" style="font-size:medium;">
                                        {{ this.partiescount }}
                                    </span>
                                </h4>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-6">
                    <div class="info-box  bg-danger-dark  text-white">
                        <div class="info-icon bg-purple">
                            <i class="fa fa fa-user fa-2x"></i>
                        </div>
                        <div class="info-details">
                            <h4>Total Active Parties:
                                <span class="badge bg-success" style="font-size:medium;">
                                    {{ this.activecount }}
                                </span>
                            </h4>
                        </div>
                    </div>
                </div> -->
                </div>
            </div>
            <div v-if="viewno == 1">
                <div class="row">
                    <div class="col-md-10 pt-0 pb-10">
                        <div class="form-horizontal">
                            <div class="form-group row">
                                <div class="col-md-3 text-end">
                                    <label class=" control-label "><b> Search Party</b></label>
                                </div>
                                <div class="col-md-6 pull-right ">
                                    <input id="myInput" type="text" v-model="searchstring"
                                        class="form-control form-cascade-control input-small">
                                </div>
                                <div class="col-md-3">
                                    <button class="btn bg-success text-white username btnsearch m0px"
                                        @click="refresh()">Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 row mt-20">
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-6">
                                <strong>Show Rec.</strong>
                            </div>
                            <div class="col-md-6">
                                <select class="form-control col-mb-1" v-model="noofrec" id="rec" @change="refresh()">
                                    <option value=10>10</option>
                                    <option value=25>25</option>
                                    <option value=50>50</option>
                                    <option value=100>100</option>
                                    <option value=500>500</option>
                                    <option value=1000>1000</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- <div class="col-md-12 mt-20">
                        <div class="row">
                            <div class="col-md-2">
                                <strong>Show Rec.</strong>
                            </div>
                            <div class="col-md-2">
                                <select class="form-control col-mb-1" v-model="noofrec" id="rec">
                                    <option value=10>10</option>
                                    <option value=25>25</option>
                                    <option value=50>50</option>
                                    <option value=100>100</option>
                                </select>
                            </div>
                        </div>
                    </div> {{ this.partiescount }} -->
                    <div class="col-md-12">
                        <div class="panel">
                            <div class="panel-body" style="overflow-x:auto;">
                                <table class="table users-table table-condensed table-hover table-bordered"
                                    id="tblmanual">
                                    <thead class="table-font">
                                        <tr>
                                            <th>S.No</th>
                                            <th>Firm Name</th>
                                            <th>Owner Name</th>
                                            <th>Contact No.</th>
                                            <th>Email</th>
                                            <th>GST </th>
                                            <th>Address</th>
                                            <!-- <th>Bank Account</th> -->
                                            <th style="width: 10%">Payment</th>
                                            <th class="wt-12 text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-font">
                                        <tr v-for="(item, index) in parties" v-bind:key="item.id">
                                            <td>{{ (currentpage - 1) * noofrec + (index + 1) }}</td>
                                            <td>{{ item.firmname }}</td>
                                            <td>{{ item.name }}</td>
                                            <td><display-mobiles :mobiles="item.mobiles"></display-mobiles></td>
                                            <td>{{ item.email }}</td>
                                            <td>{{ item.gst }}</td>
                                            <td>
                                                <div v-if="item.addressess.length > 0">
                                                    <span
                                                        v-if="item.addressess[0]?.houseno != ''">{{ item.addressess[0]?.houseno }}
                                                        ,</span>
                                                    <span
                                                        v-if="item.addressess[0]?.street != ''">{{ item.addressess[0]?.street }}
                                                        , <br></span>
                                                    <span
                                                        v-if="item.addressess[0]?.landmark != ''">{{ item.addressess[0]?.landmark }}
                                                        , <br></span>
                                                    <span
                                                        v-if="item.addressess[0]?.city != ''">{{ item.addressess[0]?.city }}
                                                    </span>
                                                    <span v-if="item.addressess[0]?.zipcode != ''">-
                                                        {{ item.addressess[0]?.zipcode }}</span>
                                                </div>

                                            </td>
                                            <!-- <td style="text-align: center;">
                                            <button class="btn btn-prime btn-xs"
                                                @click="detail(item)">Bank</button>
                                        </td> -->
                                            <td v-if="loggedinuser != null && loggedinuser.accessids.includes('19')">
                                                <button class="btn btn-prime btn-xs"
                                                    @click="detail(item)">Detail</button>
                                                <button type="button" class="btn btn-info btn-xs ml-5"
                                                    @click="partyPay(item)">Pay</button>

                                            </td>
                                            <td>
                                                <button type="button" class="btn btn-success btn-xs"
                                                    @click="editRow(item)"><i class="fa fa-edit"></i> Edit</button>
                                                <button class="btn btn-danger btn-xs ml-5"
                                                    @click="deleteParty(item)">Delete</button>
                                            </td>
                                        </tr>

                                    </tbody>
                                    <!-- <tbody class="table-font" v-if="searchdetails">
                            <tr v-for="(item,index) in searchparties" v-bind:key="item.id">
                                    <td>{{index+1}}</td>
                                    <td>{{item.firmname}}</td>
                                    <td>{{item.name}}</td>
                                    <td><display-mobiles :mobiles="item.mobiles"></display-mobiles></td>
                                    <td>{{item.email}}</td>
                                    <td>{{item.gst}}</td>
                                    <td>{{item.addressess.street}}</td>
                                    <td>{{item.bank_account}}</td>
                                    <td v-if="loggedinuser!=null && loggedinuser.accessids.includes('19')">
                                        <button type="button" class="btn btn-success btn-xs" @click="edit(item)"><i class="fa fa-edit"></i> Edit</button>
                                    </td>
                            </tr>
                        </tbody> -->
                                </table>
                                <div class="col-md-12" style="margin-top: 20px">
                                    <nav aria-label="Page navigation example" style="text-align: right !important">
                                        <paginate style="margin: 0px !important; float: right" :page-count="noofpages"
                                            :click-handler="clickCallback" :prev-text="'&laquo; Prev &nbsp;&nbsp;'"
                                            :next-text="'&nbsp;&nbsp; Next &raquo;'" :container-class="'pagination'">
                                        </paginate>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <party-form v-if="viewno == 2"></party-form>
            <party-payment-form v-if="viewno == 3" :party="party"></party-payment-form>
            <modal name="bankmodal" :width="1300" :height="600" draggable=".window-header">
                <div>
                    <div class="col-md-12 text-center pr">
                        <h3 class="window-header" style="border-bottom: 1px solid green;">Party Detail</h3>
                    </div>
                    <span>
                        <button class="party_detail_x" @click="hideBox()"><i class='bx bx-x fs_35'></i></button>
                    </span>
                    <div class="col-md-12">
                        <!-- {{ this.userdetail }} -->
                        <div class="col-md-12 bank_div">
                            <div class="col-md-12 ">
                                <div class="row ">
                                    <div class="col-md-4 ">
                                        <div class="p-5px">
                                            <label>Firm Name : </label>
                                            <span>{{ this.userdetail?.firmname ? this.userdetail?.firmname : 'N/A'
                                                }}</span>
                                        </div>

                                    </div>
                                    <div class="col-md-4 ">
                                        <div class="p-5px">
                                            <label>Owner Name : </label>
                                            <span>{{ this.userdetail?.name ? this.userdetail?.name : 'N/A' }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-4 ">
                                        <div class="p-5px">
                                            <label>Mobile no : </label>
                                            <span>{{ this.mobiles.mobile ? this.mobiles.mobile : 'N/A' }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-4 ">
                                        <div class="p-5px">
                                            <label>Email : </label>
                                            <span>{{ this.userdetail?.email ? this.userdetail?.email : 'N/A' }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-4 ">
                                        <div class="p-5px">
                                            <label>Gst : </label>
                                            <span>{{ this.userdetail?.gst ? this.userdetail?.gst : 'N/A' }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-4 ">
                                        <div class="p-5px">
                                            <label>Contact Person : </label>
                                            <span>{{ this.userdetail?.userdetail?.name ?
                                                this.userdetail?.userdetail?.name : 'N/A' }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-4 ">
                                        <label>Contact No. : </label>
                                        <span>{{ this.userdetail?.userdetail?.mobile ?
                                            this.userdetail?.userdetail?.mobile : 'N/A' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <label>Full Address : </label>
                                <span v-if="this.address?.houseno"> {{ this.address.houseno }}, </span>
                                <span v-if="this.address?.street"> {{ this.address.street }}, </span>
                                <span v-if="this.address?.landmark"> {{ this.address.landmark }}, </span>
                                <span v-if="this.address?.city"> {{ this.address.city }}, </span>
                                <span v-if="this.address?.zipcode"> {{ this.address.zipcode }} </span>
                            </div>
                        </div>
                    </div>
                    <div class="" v-if="this.banks.length > 0">
                        <div class="col-md-12 mt-10">
                            <h4>Bank Detail</h4>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-4 " v-for="item in banks" :key="item.id">
                                    <div class="bank_div">
                                        <div class="col-md-12 row">
                                            <div class="col-md-5">
                                                <label>Bank name : </label>
                                            </div>
                                            <div class="col-md-7">
                                                <span>{{ item.bankname }}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 row">
                                            <div class="col-md-5">
                                                <label>Account no. : </label>
                                            </div>
                                            <div class="col-md-7">
                                                <span>{{ item.accountno }}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 row">
                                            <div class="col-md-5">
                                                <label>IFSC : </label>
                                            </div>
                                            <div class="col-md-7">
                                                <span>{{ item.ifsccode }}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 row">
                                            <div class="col-md-5">
                                                <label>Branch : </label>
                                            </div>
                                            <div class="col-md-7">
                                                <span>{{ item.branch }}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 row">
                                            <div class="col-md-5">
                                                <label>City : </label>
                                            </div>
                                            <div class="col-md-7">
                                                <span>{{ item.city }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </modal>
        </div>

    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PartyForm from './PartyForm.vue'
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue';
import PartyPaymentForm from './PartyPaymentForm.vue'
import Paginate from "vuejs-paginate";
export default {
    components: { PartyForm, DisplayMobiles, Paginate, PartyPaymentForm },
    data() {
        return {
            activecount: 0,
            totalemp: 0,
            currentpage: 1,
            totalpage: 0,
            noofrec: 10,
            // searchdetails:false,
            // allparty: [],
            banks: [],
            searchstring: '',
            party: [],
            userdetail: [],
            address: [],
            contactuser: [],
            mobiles: []
        }
    },
    computed: {
        ...mapGetters([
            'viewno', 'parties', 'loggedinuser', 'partiescount', 'searchdata', 'edititem'
        ]),
        noofpages() {
            return parseInt(this.partiescount) / parseInt(this.noofrec)
        }
    },
    mounted() {
        this.refreshPage();
    },
    methods: {
        clickCallback(currentpage) {
            this.currentpage = currentpage
            this.$store.commit("assigncurrentpage", currentpage);
            this.refresh();
        },
        processResponse(data) {
            this.$store.commit('assignparties', data)
        },
        editRow(item) {
            console.log(item)
            this.$store.commit('assignedititem', item)
            console.log("Edit item", this.edititem)
            this.$store.commit('assignviewno', 2)
        },
        refreshPage() {
            this.$store.commit('assignloadingstatus', 1)
            let param = { noofrec: this.noofrec, currentpage: 1, firmname: '' }
            this.fetchAPI(param)
        },

        refresh() {
            this.$store.commit('assignloadingstatus', 1)
            let param = { noofrec: this.noofrec, currentpage: this.currentpage, firmname: this.searchstring }
            this.fetchAPI(param)
        },
        fetchAPI(param) {
            this.$store.dispatch("fetchparties", param)
            this.$store.dispatch("fetchpartiescount", param)
            this.$store.commit('assignloadingstatus', 0)
        },
        detail(item) {
            this.userdetail = item
            this.address = item?.addressess[0]
            this.banks = item?.banks
            this.contactuser = item?.userdetail
            this.mobiles = item.mobiles[0]
            this.$modal.show('bankmodal')
            // if (this.banks?.length > 0) {

            // } else {
            //     this.$notify({
            //         text: 'Bank detail is empty',
            //         type: 'error',
            //         duration: '8000',
            //         speed: '800',

            //     })
            // }
        },
        // searchTable() {
        //     let firm = this.parties.filter(block => block.firmname.toLowerCase().includes(this.searchstring.toLowerCase()))
        //     this.allparty = firm
        // },
        partyPay(item) {
            this.party = item
            // console.log("item",this.party.id)
            this.$store.commit('assignviewno', 3)

        },
        deleteParty(item) {
            if (confirm("Do you want to delete.")) {
                this.$store.commit('assignloadingstatus', 1)
                let param = { id: item.id }
                window.axios.post('api/users/disable', param)
                    .then((response) => this.processDisableResponse(response.data))
                    .catch((error) => console.log(error))
            }
        },
        processDisableResponse(data) {
            this.$store.commit('assignloadingstatus', 0)
            console.log(data)
            this.refreshPage()
        },
        hideBox() {
            this.$modal.hide('bankmodal')
        }
    },

}
</script>
<style scoped>
.btn-primary:hover {
    text-decoration: none !important;
    background-color: #3a476b !important;
}

.bank_div {
    /* border-bottom: 1px solid silver; */
    border: 1px solid silver;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 5px 0px;
}

.bank_div label {
    color: #475c78;
}

button:focus {
    outline: 0 !important;
}

.bank_div span {
    margin-left: 5px;
}


/* .table-bordered td, .table-bordered th {
    text-align: center;
} */
</style>