<template>
    <div>
        <div class="container" style="background: #fff;">
            <div class="col-md-12">
                <div class="panel panel-cascade">
                    <div class="panel-body" style="background-color:#c5dadf;">
                        <div class="form-horizontal cascde-forms">
                            <h1 class="page-header">Party Payment</h1>
                            <!-- <div class="form-group row CHK" >
                        <label class=" col-lg-4 col-md-3 control-label">
                         Enter Receipt No.<span style="color: rgba(255, 0, 0, 0.67)">*</span>
                        </label>
                        <div class="col-lg-6 col-md-9">
                          <input class="form-control form-cascade-control input-small"  v-model="form.receiptno" data-val="true" data-val-required="Required" id="" name="" type="number" value="" required>
                        </div>
                    </div> -->
                            <div class="form-group row CHK">
                                <label class=" col-lg-4 col-md-3 control-label">
                                    Against Bill<span style="color: rgba(255, 0, 0, 0.67)">*</span>
                                </label>
                                <div class="col-lg-6 col-md-9">
                                    <select class="form-control form-cascade-control input-small"
                                        v-model="paymentitem.salepurchaseid" @change="selectedinvoice()">
                                        <option v-for="item in allbill" :key="item.id" :value="item.id">
                                            <span>Invoice no. ({{ item.invoiceid }}) </span>
                                            <span> - Amount : ({{ item.leftamount == -1 ? item.amountwithtax :
                                            item.leftamount
                                                }})</span>
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row CHK">
                                <label class=" col-lg-4 col-md-3 control-label">
                                    Enter Amount<span style="color: rgba(255, 0, 0, 0.67)">*</span>
                                </label>
                                <div class="col-lg-6 col-md-9">
                                    <input class="form-control form-cascade-control input-small" v-model="amount"
                                        @input="checkamount()" data-val="true" data-val-required="Required" id=""
                                        name="" type="number" value="" required>
                                </div>
                            </div>
                            <!-- <div class="form-group row">
                        <label class="col-lg-4 col-md-3 control-label">Received By<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <select class="form-control form-cascade-control input-small" style="width:50%" v-model="collectedbyid">
                                <option v-for="(item) in employees" v-bind:key="item.id" :value="item.id">{{ item.name }}</option>
                            </select>
                        </div>
                    </div> -->
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-3 control-label">Pay Date<span
                                        style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                <div class="col-lg-6 col-md-9">
                                    <!-- <input class="form-control form-cascade-control input-small"  v-model="form.receiveddate" id="" name=""  type="date" value="" required> -->
                                    <vc-date-picker class="inline-block h-full" :model-config="modelConfig"
                                        v-model="transactiondate">
                                        <template v-slot="{ inputValue, togglePopover }">
                                            <div class="flex items-center">
                                                <button type="button" class="date-picker" @click="togglePopover()">
                                                    <i class='bx bx-calendar'></i>
                                                </button>
                                                <input :value="inputValue" @click="togglePopover()"
                                                    class="form-control form-cascade-control input-small"
                                                    placeholder="DD/MM/YYYY" readonly required />
                                            </div>
                                        </template>
                                    </vc-date-picker>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-3 control-label">Mode of Payment<span
                                        style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                <div class="col-lg-6 col-md-9">
                                    <select class="form-control form-cascade-control input-small" v-model="mode"
                                        @change="selectmode()">
                                        <option v-for="item in registers" :key="item.id" :value="item.id">{{ item.name
                                            }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row CHK" v-if="chequemode">
                                <label class=" col-lg-4 col-md-3 control-label">
                                    Cheque/RTGS/Transaction no.<span style="color: rgba(255, 0, 0, 0.67)">*</span>
                                </label>
                                <div class="col-lg-6 col-md-9">
                                    <input class="form-control form-cascade-control input-small" v-model="chequeno">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-3 control-label">
                                    Remark<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                <div class="col-lg-6 col-md-9">
                                    <textarea class="form-control form-cascade-control input-small" v-model="remark"
                                        cols="20" rows="2" style="height:83px;"></textarea>
                                </div>
                            </div>
                            <div class="form-group row row leadbycustomer" style="display: none">
                                <div class="col-lg-6 col-md-9">
                                    <span id="fill"></span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-3"></div>
                                <div class="col-md-3">
                                    <button type="button" class="btn btn-primary" style="margin-top:0"
                                        @click="submit()">Submit</button>
                                    <button type="button" class="btn btn-danger" @click="cancel()">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Constant from '../../components/utilities/Constants.vue'
import axios from 'axios'
export default {
    mixins: [Constant],
    props: ['party'],
    data() {
        return {
            amount: 0,
            transactiondate: '',
            mode: 0,
            allbill: [],
            remark: '',
            chequeno: '',
            bill_id: 0,
            finalamount: 0,
            paymentitem: { salepurchaseid: 0, amount: 0 },
            againstpaymentlist: [],
            chequemode: false,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD',
            },
        }
    },
    computed: {
        ...mapGetters(['registers', 'selectedstoreid']),
    },
    mounted() {
        this.$store.dispatch("fetchregisters")
        this.fetchBill()

    },
    methods: {
        fetchBill() {
            let param = { userid: this.party.id, inout: 2 }
            axios.post("api/pending/payments/fetch", param)
                .then((response) => this.processBillResponse(response.data))
                .catch((error) => console.log(error));
        },
        processBillResponse(data) {
            this.allbill = data
        },
        cancel() {
            this.$store.commit('assignviewno', 1)
            // console.log("here ", this.party.id)
        },
        initialization() {
            this.transactiondate = "",
                this.customerid = 0,
                this.mode = 0,
                this.amount = 0,
                this.chequeno = null,
                this.rtgsno = null,
                this.remark = ''
            this.bill_id = 0
        },
        selectedinvoice() {
            this.againstpaymentlist = []
            this.finalamount = 0
            let bill = this.allbill.find(block => block.id == this.paymentitem.salepurchaseid)
            if (bill.leftamount == -1) {
                this.finalamount = bill.amountwithtax
            } else {
                this.finalamount = bill.leftamount
            }
            console.log("Again payment list", this.againstpaymentlist)
            if (this.mode > 0) {
                this.againstpaymentlist = []
                this.paymentitem.amount = this.amount
                this.againstpaymentlist.push(this.paymentitem)
                console.log(this.againstpaymentlist)
            }
        },

        checkamount() {
            if (this.finalamount > 0) {
                if (parseFloat(this.amount) > parseFloat(this.finalamount)) {
                    alert("Amount is not grater than invoice amount")
                    this.amount = 0
                }
            }
        },
        submit() {
            if (this.paymentitem.salepurchaseid == 0) {
                this.$notify({ text: 'Select invoice for payment.', type: 'warn' })
            }
            else if (this.amount == 0) {
                this.$notify({ text: 'Amount is greater than 0.', type: 'warn' })
            }
            else if (this.transactiondate == '') {
                this.$notify({ text: 'Date is compulsary.', type: 'warn' })
            }
            else if (this.mode == 0) {
                this.$notify({ text: 'Mode is compulsary.', type: 'warn' })
            }
            else {
                this.$store.commit("assignloadingstatus", 1);
                let param = {
                    againstpaymentlist: this.againstpaymentlist, amount: this.amount, chequeno: this.chequeno,
                    customerid: this.party.id, fy: this.getCurrentFinancialYear(), id: this.bill_id, inout: 2,
                    mode: this.mode, remark: this.remark, storeid: this.selectedstoreid, transactiondate: this.transactiondate

                }
                console.log("param", param)
                axios.post("api/postpayment", param)
                    .then(response => this.processResponse(response.data))
                    .catch(error => console.log(error));
            }

        },
        processResponse(data) {
            console.log(data)
            this.initialization()
            this.$store.commit('assignviewno', 1)
            this.$store.commit("assignloadingstatus", 0);

        },
        selectmode() {
            if (this.amount > 0) {
                this.chequemode = false
                let chequeId = 2229
                let rtgsId = 2230
                if (chequeId == this.mode || rtgsId == this.mode) {
                    this.chequemode = true
                }
                this.againstpaymentlist = []
                this.paymentitem.amount = this.amount
                this.againstpaymentlist.push(this.paymentitem)
                console.log(this.againstpaymentlist)
            } else {
                this.mode = ''
                this.$notify({ text: 'Please fill amount.', type: 'warn' })
            }

        }
    }

}
</script>
