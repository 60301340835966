<template>
    <div class="col-md-12">
        <div class="" style="border:1px solid #b9c4d5;margin-top: 10px;border-radius: 5px;">
            <div class="col-md-12 mt-10" v-if="this.bankdetail?.length > 0">
                <label>Bank Detail</label>
                <div class="row">
                    <div class="col-md-4" v-for="(item, index) in bankdetail" :key="index">
                        <div class="bank_display">
                            <div class="col-md-12" @click="selectbank(item)">
                                <div><span>{{ index + 1 }}. </span><span>{{ item.bankname }}</span></div>
                                <div><span>{{ item.accountno }}</span> - <span>{{ item.ifsccode }}</span></div>
                                <div><span>{{ item.branch }}</span> <span>{{ item.city }}</span></div>
                            </div>
                            <div><button class="btn  trash_bin" @click="deleteBank(item)"><i
                                        class='bx bxs-trash font-17 '></i></button></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-10">
                <div class="row">
                    <div class="col-md-4">
                        <label>Bank Name</label>
                        <input type="text" class="form-control form-cascade-control input-small" v-model="bankname" />
                    </div>
                    <div class="col-md-4">
                        <label>Account No.</label>
                        <input type="text" class="form-control form-cascade-control input-small" v-model="accountno" />
                    </div>
                    <div class="col-md-4">
                        <label>IFSC </label>
                        <input type="text" class="form-control form-cascade-control input-small"
                            style="text-transform: uppercase;" v-model="ifsc" />
                    </div>
                    <div class="col-md-4">
                        <label>Branch</label>
                        <input type="text" class="form-control form-cascade-control input-small" v-model="branch" />
                    </div>
                    <div class="col-md-4">
                        <label>City</label>
                        <input type="text" class="form-control form-cascade-control input-small" v-model="city" />
                    </div>
                    <div class="col-md-4 text-center">
                        <button class="btn btn-success btn-sm mt-30" @click="saveBank()">Save Bank</button>
                        <button class="btn btn-danger btn-sm mt-30 ml-10" @click="clear()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
    data() {
        return {
            bankname: '',
            accountno: '',
            ifsc: '',
            branch: '',
            city: '',
            formdata: [],
            bankid: 0,
            selectindex: 0,
        }
    },
    mounted() {
        this.checkbank()
    },
    computed: {
        ...mapGetters(['bankdetail',]),
    },
    methods: {
        checkbank() {
            setTimeout(() => {
                if (this.bankdetail?.length > 0) {
                    this.formdata = this.bankdetail
                }
            }, 1000);

        },
        saveBank() {
            if (this.bankname == '') {
                this.$notify({
                    text: 'Enter bank name.',
                    type: 'error',
                    duration: '-1',
                    speed: '100',
                    closeOnClick: false
                })
            } else if (this.accountno == '') {
                this.$notify({
                    text: 'Enter account no.',
                    type: 'error',
                    duration: '-1',
                    speed: '100',
                    closeOnClick: false
                })
            } else {
                if (this.bankid == 0) {
                    let param = {
                        bankname: this.bankname, accountno: this.accountno, ifsccode: this.ifsc.toUpperCase(),
                        branch: this.branch, city: this.city, id: this.bankid
                    }
                    this.formdata.push(param)
                    this.$store.commit('assignbankdetail', this.formdata)
                    console.log(this.bankdetail)
                } else {
                    if (this.bankdetail?.length > 0) {
                        this.bankdetail.map((i, index) => {
                            if (index == this.selectindex) {
                                i.bankname = this.bankname,
                                    i.accountno = this.accountno,
                                    i.ifsccode = this.ifsc,
                                    i.branch = this.branch,
                                    i.city = this.city
                            }
                        })
                    }
                }
                this.clear()
            }
        },
        clear() {
            this.bankname = ''
            this.accountno = ''
            this.ifsc = ''
            this.branch = ''
            this.city = ''
        },
        cancel() {
            this.$store.commit('assignbankdetail', null)
            console.log(this.bankdetail)

        },
        selectbank(item) {
            this.bankid = item.id
            this.bankname = item.bankname
            this.accountno = item.accountno
            this.ifsc = item.ifsccode
            this.branch = item.branch
            this.city = item.city
            this.selectindex = this.bankdetail.indexOf(item)
            console.log("index", this.selectindex)

        },
        deleteBank(item) {
            if (confirm("Do you want to delete.")) {
                for (let i = 0; i < this.bankdetail.length; i++) {
                    if (this.bankdetail[i].accountno == item.accountno) {
                        this.bankdetail.splice(i, 1)
                    }
                }
                if (item.id > 0) {
                    let param = { id: item.id }
                    axios.post('api/bank/disable', param)
                        .then((response) => this.processDisableBank(response.data))
                        .catch((error) => console.log(error))
                }
            }
        },
        processDisableBank(data) {
            console.log(data)
        }

    }
}
</script>