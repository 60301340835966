<template>
    <div style="background-color:white">
        <div class="col-md-12" style="margin-top:20px">
            <div class="col-md-6">
                <h3 class="page-header">
                    <i class="fa fa fa-users animated bounceInDown show-info"></i>
                    Add Party
                </h3>
            </div>
        </div>
        <div class="row">
           
            <div class="row">
                <div class="col-md-6">
                    <div class="panel panel-cascade">
                        <div class="info-box  bg-purple  text-white">
                            <div class="info-icon bg-warning-dark">
                                <i class="fa fa fa-user fa-2x"></i>
                            </div>
                            <div class="info-details">
                                <h4>Personal Details</h4>
                            </div>
                        </div>
                        <div class="panel-body">
                            <div class="form-horizontal cascde-forms">

                                <div class="form-group row ">
                                    <label class="col-lg-4 col-md-3 control-label">Firm Name<span
                                            style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small"
                                            v-model="form.firmname" type="text" required>

                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-lg-4 col-md-3 control-label">Owner Name<span
                                            style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="form.name"
                                            type="text" required>
                                        <div class="col-md-12 form-error"><span class="help text-danger"
                                                v-text="form.errors.get('name')"></span></div>
                                    </div>
                                </div>
                                <div class="form-group row Authority" style="">
                                    <label class="col-lg-4 col-md-3 control-label">Owner Mobile No.<span
                                            style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                    <div class="col-lg-8 col-md-9">
                                        <mobiles></mobiles>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-md-3 control-label">Email<span
                                            style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" type="text"
                                            v-model="form.email" required>
                                        <div class="col-md-12 form-error"><span class="help text-danger"
                                                v-text="form.errors.get('email')"></span></div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-md-3 control-label">GST<span
                                            style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" type="text"
                                            v-model="form.gst" style="text-transform: uppercase;" required>

                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-md-3 control-label">Contact Person<span
                                            style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" type="text"
                                            v-model="contact" required>

                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-md-3 control-label">Contact Person No.<span
                                            style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" type="text"
                                            v-model="mobile" @input="mobilenoValidation()" required>
                                        <!-- <div class="text-end ">{{ this.mobile?.length }}/10</div> -->

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div> <!-- / purple Panel -->
                </div>
                <div class="col-md-6">
                    <div class="panel panel-cascade">
                        <div class="info-box  bg-purple  text-white">
                            <div class="info-icon bg-warning-dark">
                                <i class="fa fa fa-user fa-2x"></i>
                            </div>
                            <div class="info-details">
                                <h4>Address Details</h4>
                            </div>
                        </div>
                        <div class="panel-body">
                            <div class="form-horizontal cascde-forms">
                                <address-form :office="1"></address-form>

                            </div>
                        </div>
                    </div> <!-- / purple Panel -->
                </div>
                <div class="col-md-12">
                    <div class="">
                        <bank-detail></bank-detail>
                    </div>

                </div>
                <div class="col-md-4 pull-right">
                    <input type="button" style="background-color:#495b79!important;margin-top:23px;margin-left:35px;"
                        :disabled="btndisable==true ?true:false" value="Submit" @click="submit()" class="btn bg-primary text-white btn-sm chkcondition">
                    <input type="button" style="background-color:#495b79!important;margin-top:23px;margin-left:35px;"
                        value="Cancel" @click="cancel()" class="btn bg-primary text-white btn-sm chkcondition">

                </div>
            </div>
           
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Mobiles from '../../components/mobile/Mobiles.vue'
import AddressForm from '../../components/address/AddressForm.vue'
import Constants from '../../components/utilities/Constants.vue'
import BankDetail from '../bank/BankDetail.vue'
export default {
    mixins: [Constants],
    components: { Mobiles, AddressForm, BankDetail },
    data() {
        return {
            form: new window.Form({
                id: 0,
                firmname: '',
                name: '',
                email: '',
                gst: '',
                mobiles: null,
                isparty: 1,
                iscustomer:1,

                userdetail: [],
                bankdetail: null,
                addressess: null,
            }),
            contact: '',
            mobile: '',
            contactid: 0,
            btndisable:false
        }
    },
    computed: {
        ...mapGetters([
            'viewno', 'edititem', 'mobiles', 'addressess', 'bankdetail'
        ]),


    },
    mounted() {
        this.initializedata();
    },
    watch: {
        edititem: function () {
            this.initializedata();
        }
    },
    methods: {
        initializedata() {
            // this.reset();
            if (this.edititem != null) {
                this.form.id = this.edititem.id;
                this.form.name = this.edititem.name;
                this.form.firmname = this.edititem.firmname;
                this.form.email = this.edititem.email;
                this.form.gst = this.edititem.gst;
                this.$store.commit('assignmobiles', this.edititem.mobiles == null ? [] : this.edititem.mobiles)
                this.$store.commit('assignaddressess', this.edititem.addressess == null ? [] : this.edititem.addressess)
                if (this.edititem.addressess != null && this.edititem.addressess?.length > 0) {
                    this.$store.commit('assignselectedaddress', this.edititem.addressess[0])
                }
                this.contactid = this.edititem.userdetail?.id
                this.contact = this.edititem.userdetail?.name
                this.mobile = this.edititem.userdetail?.mobile
                this.$store.commit('assignbankdetail', this.edititem.banks)
            }
        },
        keydownform(event) {
            this.form.errors.clear(event.target.name);
            this.errormessage = '';
        },
        submit() {
            if(this.form.firmname==null ||  this.form.firmname==''){
                this.$notify({text:'Firmname is mandatory',type:'warn'})
            }else {
                this.$store.commit('assignloadingstatus',1)
                this.btndisable = true
                let userdetail = { id: this.contactid, contact: this.contact, contactno: this.mobile }
                this.form.userdetail = userdetail
                this.form.mobiles = this.mobiles
                this.form.addressess = this.addressess
                this.form.bankdetail = this.bankdetail
                this.form.gst = this.form?.gst?.toUpperCase()
                // console.log("my form", this.form);
                
                this.form.submit(this, 'post', 'api/user/updateprofile/others')
                    .then(response => this.processResponse(response))
                    .catch(error => console.log(error));
            }
            
        },
        processResponse(data) {
            // this.$store.commit('updateappendparties', data.user)
            this.$store.commit('assignloadingstatus',0)
            this.$notify({ text: 'Registered Successfully',type: 'success' })
            this.$store.commit('assignviewno', 1)
            this.$store.commit('assignedititem', null)
            this.btndisable = false
            
            setTimeout(() => {
                if(data.success){
                    window.location.reload();
                }
            }, 500);
            
        },
        cancel() {
            this.reset();
            this.$store.commit('assignviewno', 1)
        },

    }
}
</script>